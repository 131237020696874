<template>
  <div class="popup-detail">
    <el-dialog
      :title="todo == 'add' ? '【新增】维修登记' : (todo == 'audit' ? '【审核】维修登记' : '【查看】维修登记')"
      width="50%"
      :close-on-click-modal="false"
      :visible.sync="visible">
      <el-form :model="dataForm" :rules="dataRule" ref="dataForm" size="small" label-width="90px">
        <el-row :gutter="50" v-if="dataForm.id">
          <el-steps :active="stepsCurrent" align-center finish-status="success">
            <el-step :title="'申请' + '(' + dataForm.driverName + ')'" :description="formattedDateTime(dataForm.createTime)" />
            <el-step :title="'审批' + '(' + (dataForm.processName == undefined ? '' : dataForm.processName) + ')'" :description="formattedDateTime(dataForm.auditTime)" />
            <el-step title="完成" :description="formattedDateTime(dataForm.successTime)" />
          </el-steps>
          <br />
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="申请类型" prop="requestType">
              <el-select class="item-choose" v-model="dataForm.requestType" :disabled="todo!='add'" style="width: 100%;" filterable>
                <el-option
                  v-for="item in driverRepairList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
<!--            <el-form-item label="申请日期" prop="requestDate">-->
<!--              <el-input v-model="dataForm.requestDate" :disabled="true"></el-input>-->
<!--            </el-form-item>-->
            <el-form-item label="申请日期" prop="requestDate">
              <el-date-picker
                v-model="dataForm.requestDate"
                type="datetime"
                default-time="12:00:00"
                :disabled="todo!='add'"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择日期时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="申请人" prop="vehiclePlate">
              <el-input v-model="dataForm.driverName" :disabled="todo!='add'"  v-show="todo!='add'"></el-input>
              <el-cascader  v-show="todo=='add'"
                            :disabled="todo!='add'"
                            v-model="dataForm.driverName"
                            :options="driverList"
                            ref="cascaderTake"
                            style="width: 100%"
                            clearable
                            filterable
                            change-on-select
                            :props="{ checkStrictly: false, value: 'id', label: 'name' }"
                            placeholder="请选择"></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话" prop="driverPhone">
              <el-input v-model="dataForm.driverPhone" :disabled="todo!='add'"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="车牌号" prop="vehiclePlate">
              <el-input v-model="dataForm.vehiclePlate" :disabled="todo!='add'"  v-show="todo!='add'"></el-input>
              <el-cascader  v-show="todo=='add'"
                :disabled="todo!='add'"
                v-model="dataForm.vehiclePlate"
                :options="vehicleList"
                ref="cascaderTake"
                style="width: 100%"
                clearable
                filterable
                change-on-select
                :props="{ checkStrictly: false, value: 'vehiclePlate', label: 'vehiclePlate' }"
                placeholder="请选择"></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="行驶里程" prop="travelMileage">
              <el-input v-model="dataForm.travelMileage" :disabled="isView"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="24">
            <el-form-item label="申请说明" prop="repairDesc">
              <el-input :rows="2"
                        :disabled="isView"
                        type="textarea"
                        v-model="dataForm.repairDesc"
                        placeholder="申请说明"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="24">
            <el-form-item label="维修前附件">
              <al-upload :fileData="beforeFileList"
                         :fileUrl="uploadUrl"
                         :isView="todo != 'add'"
                         :format="'file'"
                         :type="'repair'"
                         @getFileData="getFileDataBefore"
                         :limit="12"
                         :businessType="5007"></al-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <h3 style="margin-left: 20px">审核信息</h3>
        <el-row :gutter="50">
          <el-col :span="24">
            <el-form-item label="督导意见" prop="analysis">
              <el-input :rows="2"
                        :disabled="isView"
                        type="textarea"
                        v-model="dataForm.analysis"
                        placeholder="督导意见"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="维修供应商" prop="repairItem">
              <el-select class="item-choose" filterable
                         @change="checkChange"
                         v-model="dataForm.repairItem"
                         :disabled="isView"
                         style="width: 100%;">
                <el-option
                  v-for="item in repairFactoryList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="联系人" prop="linkman">
              <el-input v-model="dataForm.linkman" :disabled="isView"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话" prop="phone">
              <el-input v-model="dataForm.phone" :disabled="isView"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div>
          <h3 style="margin-left: 20px">维保信息</h3>
          <el-row :gutter="50">
            <el-col :span="12">
              <el-form-item label="现金支付" prop="repairCost">
                <el-input v-model="dataForm.cashPayment" :disabled="isView"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="月结支付" prop="repairCost">
                <el-input v-model="dataForm.repairCost" :disabled="isView"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="完成时间" prop="successTime">
                <el-date-picker
                  v-model="dataForm.successTime"
                  type="datetime"
                  default-time="12:00:00"
                  :disabled="isView"
                  format="yyyy-MM-dd HH:mm"
                  value-format="yyyy-MM-dd HH:mm"
                  placeholder="选择日期时间">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="24">
              <el-form-item label="维保说明" prop="repairRemark">
                <el-input :rows="2"
                          :disabled="isView"
                          type="textarea"
                          v-model="dataForm.repairRemark"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="24">
              <el-form-item label="维修后附件">
                <al-upload :fileData="afterFileList"
                           :fileUrl="uploadUrl"
                           :isView="isView"
                           :format="'file'"
                           :type="'repair'"
                           @getFileData="getFileData"
                           :limit="12"
                           :businessType="5008"></al-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取消</el-button>
        <el-button type="primary" @click="dataFormSubmit()" v-if="todo != 'view'">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {formattedDateTime} from "@/utils/common";

export default {
  data () {
    return {
      stepsCurrent: 1,
      visible: false,
      dataForm: {
        id: null,
        driverName: null,
        driverPhone: null,
        requestType: null,
        requestDate: null,
        travelMileage: null,
        repairName: null,
        repairDesc: null,
        afterFileList: [],
        beforeFileList: [],
        linkman: null,
        phone: null,
        analysis: null,
        repairStatus: null,
        repairItem: null,
        cashPayment: null,
        repairCost: null,
        repairRemark: null,
        successTime: null,
        vehiclePlate: null,
        driverId: null
      },
      uploadUrl: '',
      beforeFileList: [],
      afterFileList: [],
      todo: '',
      dataRule: {},
      vehicleList: [],
      driverList: [],
      driverRepairList: [],
      repairFactoryList: []
    }
  },
  activated () {
    this.getVehicle()
    this.getDriver()
  },
  computed: {
    dictTypeMap: {
      get () {
        return this.$store.state.common.dictTypeMap
      }
    },
    isView () {
      return this.todo === 'view'
    }
  },
  methods: {
    formattedDateTime,
    getFileData (data) {
      let fileList = data.fileList
      this.dataForm.afterFileList = []
      for (let i = 0; i < fileList.length; i++) {
        this.dataForm.afterFileList.push(fileList[i])
      }
    },
    getFileDataBefore (data) {
      let fileList = data.fileList
      this.dataForm.beforeFileList = []
      for (let i = 0; i < fileList.length; i++) {
        this.dataForm.beforeFileList.push(fileList[i])
      }
    },
    clearFileList () {
      this.uploadUrl = this.$http.adornUrl('/upload/file')
      this.accessToken = this.$cookie.get('TmsToken')
      this.beforeFileList = []
      this.afterFileList = []
    },
    initFileList (data) {
      let beforeFileList = data.beforeFileList
      if (beforeFileList) {
        for (let i = 0; i < beforeFileList.length; i++) {
          this.beforeFileList.push({
            id: beforeFileList[i].id,
            name: beforeFileList[i].name,
            url: beforeFileList[i].url,
            businessType: beforeFileList[i].businessType,
            status: beforeFileList[i].status,
            uid: beforeFileList[i].uid
          })
        }
      }
      let afterFileList = data.afterFileList
      this.dataForm.afterFileList = data.afterFileList
      if (afterFileList) {
        for (let i = 0; i < afterFileList.length; i++) {
          this.afterFileList.push({
            id: afterFileList[i].id,
            name: afterFileList[i].name,
            url: afterFileList[i].url,
            businessType: afterFileList[i].businessType,
            status: afterFileList[i].status,
            uid: afterFileList[i].uid
          })
        }
      }
    },
    init (todo, id) {
      this.stepsCurrent = 1
      this.dataForm.id = id
      this.todo = todo
      this.driverRepairList = this.dictTypeMap.driver_repair
      this.repairFactoryList = this.dictTypeMap.repair_factory
      if (todo === 'add') {
        this.dataForm.beforeFileList = []
        this.dataForm.afterFileList = []
        this.driverRepairList = this.dictTypeMap.driver_repair.filter(item => item.status === 1)
        this.repairFactoryList = this.dictTypeMap.repair_factory.filter(item => item.status === 1)
      }
      this.clearFileList()
      this.visible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields()
      })
      if (this.dataForm.id) {
        this.$http({
          url: this.$http.adornUrl(`/driverRepair/findById/${this.dataForm.id}`),
          method: 'get',
          params: this.$http.adornParams()
        }).then(({ data }) => {
          if (data) {
            this.dataForm.id = data.id
            this.dataForm.driverName = data.driverName
            this.dataForm.driverPhone = data.driverPhone
            this.dataForm.vehiclePlate = data.vehiclePlate
            this.dataForm.requestType = data.requestType
            this.dataForm.requestDate = data.requestDate
            this.dataForm.travelMileage = data.travelMileage
            this.dataForm.repairName = data.repairName
            this.dataForm.repairDesc = data.repairDesc
            this.dataForm.repairStatus = data.repairStatus
            this.dataForm.analysis = data.analysis
            this.dataForm.repairItem = data.repairItem
            this.dataForm.auditName = data.auditName
            this.dataForm.auditTime = data.auditTime
            this.dataForm.phone = data.phone
            this.dataForm.linkman = data.linkman
            this.dataForm.repairCost = data.repairCost
            this.dataForm.cashPayment = data.cashPayment
            this.dataForm.repairRemark = data.repairRemark
            this.dataForm.successTime = data.successTime
            this.dataForm.createTime = data.createTime
            this.dataForm.processName = data.processName
            if (this.dataForm.repairStatus == 0) {
              this.stepsCurrent = 1
            } else if (this.dataForm.repairStatus == 1) {
              this.stepsCurrent = 2
            } else if (this.dataForm.repairStatus == 2) {
              this.stepsCurrent = 3
            }


            this.initFileList(data)
          }
        })
      } else {
        this.dataForm.cashPayment = null
      }
    },
    // 表单提交
    dataFormSubmit () {
      let methodName = !this.dataForm.id ? 'add' : 'audit'
      let method = !this.dataForm.id ? 'put' : 'post'
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          let repairStatus = this.dataForm.repairStatus
          if (this.todo === 'audit') {
            repairStatus = 1
          }
          if (this.todo === 'add') {
            repairStatus = 0
          }
          if (this.todo === 'back') {
            repairStatus = 0
          }
          if (Array.isArray(this.dataForm.vehiclePlate)) {
            this.dataForm.vehiclePlate = this.dataForm.vehiclePlate[1] // 只传入车牌号
          }
          if (Array.isArray(this.dataForm.driverName)) {
            this.dataForm.driverId = this.dataForm.driverName[1] // 只传入司机ID
          }
          this.$http({
            url: this.$http.adornUrl(`/driverRepair/` + methodName),
            method: method,
            data: {
              id: this.dataForm.id,
              analysis: this.dataForm.analysis,
              repairItem: this.dataForm.repairItem,
              repairName: this.dataForm.repairName,
              repairDesc: this.dataForm.repairDesc,
              linkman: this.dataForm.linkman,
              phone: this.dataForm.phone,
              repairStatus: repairStatus,
              repairCost: this.dataForm.repairCost,
              cashPayment: this.dataForm.cashPayment,
              successTime: this.dataForm.successTime,
              repairRemark: this.dataForm.repairRemark,
              afterFileList: this.dataForm.afterFileList, // 修改后附件
              beforeFileList: this.dataForm.beforeFileList, // 修改前附件
              travelMileage: this.dataForm.travelMileage,
              requestDate: this.dataForm.requestDate,
              requestType: this.dataForm.requestType,
              vehiclePlate: this.dataForm.vehiclePlate,
              driverId: this.dataForm.driverId
            }
          }).then(({ data }) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error('操作失败')
            }
          })
        }
      })
    },
    checkChange () {
      let repairFactoryList = this.dictTypeMap.repair_factory
      for (let i = 0; i < repairFactoryList.length; i++) {
        let element = repairFactoryList[i]
        if (this.dataForm.repairItem === element.name) {
          this.dataForm.linkman = element.linkman
          this.dataForm.phone = element.phone
          break
        }
      }
    },
    getVehicle () {
      this.$http({
        url: this.$http.adornUrl('/vehicleInfo/findTreeList'),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.vehicleList = data
      })
    },
    getDriver () {
      this.$http({
        url: this.$http.adornUrl('/driver/findTreeList'),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.driverList = data
      })
    }
  }
}
</script>
<style lang="less">
.title {
  font-size: 18px;
  font-weight: 700;
}

.popup-detail {
  .el-dialog__body {
    padding: 0;
    max-height: 750px;
    overflow-x: hidden;
  }
  .infoBox {
    // box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px, rgba(0, 0, 0, 0.12) 0px 0px 6px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 3px, rgba(0, 0, 0, 0.08) 0px 0px 3px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
    padding: 10px;
  }

  .el-dialog {
    margin-top: 0 !important;
    top: 50% !important;
    transform: translate(0, -50%);
  }

  .infoBox2 {
    th.yellow-th {
      background: yellow !important;
    }

    th.pink-th {
      background: pink !important;
    }

    td {
      padding: 0 0;
    }
  }
}
</style>
